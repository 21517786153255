<template>
    <pagination :title="trackerName" :response="response" :entities="entities" :with-edit-icon="true" header-colour-class="bg-transparent" :hasStickyHeader="true" :editing="editing" @editClicked="editClicked" @saveClicked="saveClicked">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:title>
            <div class="flex">
                <span class="mr-2 font-bold">{{trackerName}}</span>
                <status-badge v-if="entities.properties.get('pre_check_required') === '1'" status="Pre-Check Required"></status-badge>
            </div>

        </template>
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <template v-for="(qa_tracker_column_name,key) in headers">
<!--                //'sm:pl-6 left-0 sticky bg-secondary-lightbacka'-->
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider" :class="{'sm:pl-6 left-0 sticky bg-v3-base-200 dark:bg-v3-base-700': key===0}"  v-if="qa_tracker_column_name !== 'priority' && qa_tracker_column_name !== 'update-link'">
                    {{qa_tracker_column_name}}
                    <span class="flex" v-if="editing && savePriorityAction && qa_tracker_column_name !== 'name'">
                        <span class="inline-flex" v-if="key > 1" >
                          <svg @click="moveHeaderLeft(key)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>

                        </span>
                        <span @click="moveHeaderRight(key)" v-if="
                            (headers[headers.filter(header => header !== 'priority' && header !== 'update-link').length] !== 'priority' && key !== headers.filter(header => header !== 'priority' && header !== 'update-link').length) ||
                            (headers[headers.filter(header => header !== 'priority' && header !== 'update-link').length] === 'priority' && key !== headers.filter(header => header !== 'priority' && header !== 'update-link').length -1)
                        ">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>
                      </span>
                    <span v-if="editing && getCategoryDetails(qa_tracker_column_name) && getCategoryDetails(qa_tracker_column_name)['update-link']" class="cursor-pointer font-normal underline text-gray-400" @click.prevent="removeCategory(getCategoryDetails(qa_tracker_column_name), key)">remove</span>
                </th>
            </template>
        </template>
        <template v-slot:items>
            <template v-for="(qa_trackers,key) in qaTrackers">
                <tr v-if="qa_trackers.name.replaceAll('/',' / ') !== 'priority'" >
                    <td class="whitespace-nowrap py-4 px-4 text-sm font-bold sm:px-6  items-center sticky left-0 bg-v3-base-200 dark:bg-v3-base-700 text-v3-gray-600 dark:text-v3-gray-400">
                        <span class="inline-flex">
                            <template v-if="!editing">
                                {{qa_trackers.name.replaceAll('/',' / ')}}
                            </template>
                            <component class="w-28" v-else is="text-input"
                                       name="name"
                                       title="name"
                                       :showLabel="false"
                                       v-model="qaTrackerNames[qa_trackers.name]"
                                       @input="$event => nameChange(qa_trackers.name, $event)"></component>


                          <span v-if="editing && savePriorityAction">
                            <span v-if="key !== 0">
                              <svg @click="moveTrackerDown(qa_trackers)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>
                            </span>
                            <span>
                              <svg v-if="key !== qaTrackers.length - 1" @click="moveTrackerUp(qa_trackers)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                            </span>
                          </span>
                        </span>
                        <span v-if="editing && qa_trackers['update-link']" class="flex cursor-pointer font-normal underline text-gray-400 center" @click.prevent="removeIdentifier(qa_trackers['update-link'], key)">remove</span>
                        <span class="text-sm text-v3-red" v-if="errors.has(key+'.name')"><br>{{errors.first(key+'.name')}}</span>
                    </td>
                    <template v-if="headers">
                        <template v-for="(qa_tracker_column_name,index) in headers.slice(1)">
                            <td class="whitespace-nowrap py-4 pl-4 text-sm font-bold" v-if="qa_tracker_column_name !== 'priority' && qa_tracker_column_name !== 'update-link'">
                                <template v-if="qa_trackers[qa_tracker_column_name]">
                                    <template v-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-started'">
                                        <span class="flex justify-center">
                                            <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                                {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_first_name']}}<br>
                                                {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_surname']}}<br>
                                                {{getParsedData(qa_trackers[qa_tracker_column_name])['date_of_works']}}
                                            </a>
                                        </span>
                                    </template>
                                    <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-questions-not-confirmed'">
                                        <span class="flex justify-center">
                                            <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                                {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_first_name']}}<br>
                                                {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_surname']}}<br>
                                                {{getParsedData(qa_trackers[qa_tracker_column_name])['date_of_works']}}
                                            </a>
                                        </span>
                                    </template>
                                    <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-completed'">
                                        <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="currentColor" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 256a160 160 0 1 1 320 0A160 160 0 1 1 0 256z"/></svg>
                                        </a>
                                    </template>
                                    <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'created'">
                                        <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                            <svg class="h-6 w-6 text-v3-blue rounded-full bg-v3-blue bg-opacity-20 p-1 mx-auto" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7138 6.175L15.6972 9.16667H5.5555V10.8333H15.6972L12.7055 13.825L13.8888 15L18.8888 10L13.8888 5L12.7138 6.175ZM2.22217 5V15H3.88883V5H2.22217Z" fill="#0D99FF"/>
                                            </svg>

                                        </a>
                                    </template>
                                    <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'signed'">
                                        <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                            <svg class="h-6 w-6 text-v3-purple rounded-full bg-v3-purple bg-opacity-20 p-1 mx-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.12498 17.3754C9.60832 16.7921 9.28332 15.1837 8.53332 14.1671C7.79165 13.1254 6.76665 12.4087 5.73332 11.7171C4.99588 11.2526 4.33755 10.673 3.78332 10.0004C3.54998 9.72539 3.07498 9.21706 3.55832 9.11706C4.04998 9.01706 4.89998 9.50039 5.33332 9.68373C6.09165 10.0004 6.84165 10.3671 7.54165 10.8004L8.38332 9.38373C7.08332 8.52539 5.41665 7.76706 3.86665 7.54206C2.98332 7.40873 2.04998 7.59206 1.74998 8.55039C1.48332 9.37539 1.90832 10.2087 2.39165 10.8587C3.53332 12.3837 5.30832 13.1171 6.63332 14.4337C6.91665 14.7087 7.25832 15.0337 7.42498 15.4171C7.59998 15.7837 7.55832 15.8087 7.16665 15.8087C6.13332 15.8087 4.84165 15.0004 3.99998 14.4671L3.15832 15.8837C4.43332 16.6671 6.56665 17.8921 8.12498 17.3754ZM17.3666 4.37539C17.55 4.19206 17.55 3.89206 17.3666 3.71706L16.2833 2.63373C16.1962 2.54922 16.0797 2.50195 15.9583 2.50195C15.837 2.50195 15.7204 2.54922 15.6333 2.63373L14.7833 3.48373L16.5166 5.21706M9.16665 9.10039V10.8337H10.9L16.025 5.70873L14.2916 3.97539L9.16665 9.10039Z" fill="#9747FF"/>
                                            </svg>

                                        </a>
                                    </template>
                                    <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'approved'">
                                        <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                            <svg class="h-6 w-6 text-v3-green rounded-full bg-v3-green bg-opacity-20 p-1 mx-auto" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.16691 9.49935L1.66691 5.99935L0.500244 7.16602L5.16691 11.8327L15.1669 1.83268L14.0002 0.666016L5.16691 9.49935Z" fill="#14AE5C"/>
                                            </svg>

                                        </a>
                                    </template>
                                    <template v-else>
                                        <svg class="h-6 w-6 text-v3-red rounded-full bg-v3-red bg-opacity-20 p-1 mx-auto" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.3888 5.34102L15.2138 4.16602L10.5555 8.82435L5.89717 4.16602L4.72217 5.34102L9.3805 9.99935L4.72217 14.6577L5.89717 15.8327L10.5555 11.1743L15.2138 15.8327L16.3888 14.6577L11.7305 9.99935L16.3888 5.34102Z" fill="#F13C27"/>
                                        </svg>
                                    </template>
                                </template>
                                <template v-else>
                                    <svg class="h-6 w-6 text-v3-red rounded-full bg-v3-red bg-opacity-20 p-1 mx-auto" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.3888 5.34102L15.2138 4.16602L10.5555 8.82435L5.89717 4.16602L4.72217 5.34102L9.3805 9.99935L4.72217 14.6577L5.89717 15.8327L10.5555 11.1743L15.2138 15.8327L16.3888 14.6577L11.7305 9.99935L16.3888 5.34102Z" fill="#F13C27"/>
                                    </svg>
                                </template>
                            </td>
                        </template>
                    </template>
                </tr>
            </template>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import Siren from "super-siren";
import axios from "axios";
import StatusBadge from "@/components/StatusBadge.vue";

Array.prototype.move = function(from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
};

export default {
  name: 'QaTrackerPagination',
    data(){
      return {
          'headers': null,
          'trackerName': 'Qa Tracker',
          'removingCategory': false,
          'removingIdentifier': false,
          'editing': false,
          'hasChanged': []
      }
    },
    components: {StatusBadge, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        qaTrackers(){
            if(!this.entities){
                return null;
            }
            return this.entities.properties.get('qa_tracker');
        },
        savePriorityAction() {
            return this.response.body.actions.filter(action => action.name === 'update-priority').first();
        },
        qaTrackerNames(){
            let payload = [];
            this.qaTrackers.forEach(function(tracker){
                payload[tracker.name] = tracker.name;
            })
            return payload;
        }
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    mounted() {
        if(this.entities && this.qaTrackers){
            this.headers = Object.keys(this.qaTrackers[0]).splice(1);
            this.headers.splice(this.headers.indexOf('change_name_link'),1);
            this.trackerName = this.entities.properties.get('name')
        }
    },
    methods:{
        getParsedData(data){
            return JSON.parse(data);
        },
        goToQualityAssessment(qaId){
            this.$router.push({ name: 'quality-assessment', params: { id: qaId } })
        },
        goToPreCheck(preCheckId){
            this.$router.push({ name: 'pre-check', params: { id: preCheckId } })
        },
        moveTrackerUp(tracker){
            let index = this.qaTrackers.indexOf(tracker);
            if(index < this.qaTrackers.length) {
                this.qaTrackers.move(index, index + 1);
                this.$emit('change');
            }
        },
        moveTrackerDown(tracker){
            let index = this.qaTrackers.indexOf(tracker);
            if(index > 0) {
                this.qaTrackers.move(index, index - 1);
                this.$emit('change');
            }
        },
        moveHeaderLeft(key){
            if(key > 1) {
                this.headers.move(key, key - 1);
                this.$emit('change');
            }
        },
        moveHeaderRight(key){
            if(key > 0 && key < this.headers.length - 1) {
                console.log(this.headers);
                this.headers.move(key, key + 1);
                this.$emit('change');
            }
        },
        editClicked(){
            this.$emit('editClicked');
            this.editing = true
        },
        saveClicked(){
            this.$emit('saveClicked');
            this.$setLaravelValidationErrorsFromResponse(
                ''
            );
            this.savePriorities();
            this.saveNames();
        },
        async saveNames(){
            let hasErrors = false;

            await this.qaTrackers.forEach( async function(tracker,index){
                let newName = this.qaTrackerNames[tracker.name];
                if(newName !== tracker.name){
                    await axios.patch(tracker.change_name_link, {'name' : newName}, {
                        headers: {
                            'Authorization': 'Bearer ' + this.accessToken
                        }
                    })
                        .then(response => {
                             tracker.name = newName;
                        }).catch(response => {
                            // tracker.name = newName;
                            hasErrors = true;
                            console.log(hasErrors);
                            if(response.response.status !== 422){
                                alert('There was an error processing your request.' + response);
                            }
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(response.response.request.responseText), index.toString()
                        );

                    })
                }
            }.bind(this));
            // console.log(hasErrors);
            // if(!hasErrors){
            //     this.$store.dispatch(
            //         "setMessage",
            //         'The names have been updated successfully.'
            //     );
            // }
        },
        savePriorities(){
            if(!this.savingPriorities) {
                this.savingPriorities = true;
                this.savePriorityAction.perform({
                    'trackers': JSON.stringify(this.qaTrackers),
                    'categories': JSON.stringify(this.headers.slice(1, this.headers.length - 1))
                }).then(() => {
                    this.editing = false;
                    this.savingPriorities = false;
                }).error(()=> {
                    this.editing = false;
                    this.savingPriorities = false;
                });
            }
        },
        getCategoryDetails(categoryName){
            if(this.response.body.properties.get('qa_category_ids').filter(category => category.name === categoryName)[0])
            {
                return this.response.body.properties.get('qa_category_ids').filter(category => category.name === categoryName)[0];
            }
            return null;
        },
        removeCategory(removalLink, key)
        {
            if(!this.removingCategory) {
                this.removingCategory = true;
                let that = this;
                axios.patch(removalLink['update-link'], {}, {
                    headers: {"Authorization": `Bearer ${this.accessToken}`}
                }).then(function () {
                    that.headers.splice(key, 1);
                    that.$store.dispatch(
                        "setMessage",
                        'The tracker was updated successfully.'
                    );
                    that.removingCategory = false;
                }).catch(function(){
                    that.removingCategory = false;
                })
            }
        },
        removeIdentifier(removalLink, key)
        {
            if(!this.removingIdentifier) {
                this.removingIdentifier = true;
                let that = this;
                axios.patch(removalLink, {}, {
                    headers: {"Authorization": `Bearer ${this.accessToken}`}
                }).then(function () {
                    that.qaTrackers.splice(key, 1);
                    that.$store.dispatch(
                        "setMessage",
                        'The tracker was updated successfully.'
                    );
                    that.removingIdentifier = false;
                }).catch(function(){
                    that.removingIdentifier = false;
                })
            }
        },
        nameChange(name, value){
            this.hasChanged[name] = true;
        }
    }
}
</script>
